import React, { useState, useEffect } from "react";
import { useMedia } from "use-media";
import Layout from "../../../components/chat-channel/Layout";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Container from "../../../components/chat-channel/Container";
import TitleAndMetaTags from "../../../components/common/TitleAndHeader";
import { RequestForm } from "../../../components/form";
import { OnScrollPopup } from "../hr-chatbot";
import { GoToPopup } from "../../homepage";
import { CookiesPoup } from "../../homepage";
import {
  cta_1,
  cta_1_mob,
  cta_2,
  cta_2_mob,
  ExistingBlogCta,
} from "./4-reasons-to-use-ms-teams-as-a-digital-helpdesk";

import "react-accessible-accordion/dist/fancy-example.css";

const topImage =  require("../../../assets/images/market_ui/goto_testimonial_cta.png");
const image1 = require("../../../assets/images/market_ui/slack/img_1.png");
const image2 = require("../../../assets/images/market_ui/slack/img_2.png");
const image3 = require("../../../assets/images/market_ui/slack/img_3.png");
const image4 = require("../../../assets/images/market_ui/slack/img_4.png");

const BlogHeader = require("../../../assets/img/blogs/blog_covid_header.webp");
const Section1 = require("../../../assets/img/blogs/chatbot_best_1.png");
const Section2 = require("../../../assets/img/blogs/chatbot_best_2.png");
const Section3 = require("../../../assets/img/blogs/chatbot_best_3.jpeg");
const Section4 = require("../../../assets/img/blogs/Blog_Chatbot Best Practices_1.jpg");
const Section5 = require("../../../assets/img/blogs/chatbot_best_5.png");
const Section6 = require("../../../assets/img/blogs/chatbot_best_6.png");
const Section7 = require("../../../assets/img/blogs/chatbot_best_7.png");
const Section8 = require("../../../assets/img/blogs/chatbot_best_8.png");
const SectionLast = require("../../../assets/img/blogs/chatbot_best_final.gif");

const ldSchema = {
  "@context": "https://schema.org",
  "@type": "BlogPosting",
  mainEntityOfPage: {
    "@type": "WebPage",
    "@id":
      "https://workativ.com/conversational-ai-platform/slack-chatbot-guide",
  },
  headline: "Slack Chatbot Guide for your Business",
  image: "",
  author: {
    "@type": "Organization",
    name: "Team Workativ",
  },
  publisher: {
    "@type": "Organization",
    name: "Workativ",
    logo: {
      "@type": "ImageObject",
      url:
        "https://workativ.com/static/workativ-logo-5eea21f2dcd20bbbbf52debb41c0f26e.png",
    },
  },
  datePublished: "2021-08-05",
  dateModified: "2021-08-05",
};

export default function FeatureHomePage() {
  const isSmall = useMedia({ maxWidth: "520px" });

  return (
    <>
      <TitleAndMetaTags
        title="Enable IT Helpdesk with automation to overcome COVID-19 remote support challenges"
        description="Learn how customers can overcome pandemic challenges in delivering great IT Helpdesk to employees by using process automation and conversational ai tools "
        keywords={[
          "Slack Chatbot, Automation, AI Chatbot",
          "workativ free trial",
          "Slack chatbot free trial",
          "Slack Chatbot",
        ]}
        ogImage={BlogHeader}
        schema={ldSchema}
      />
      <section className="market_main_wrapper">
        <Container>
          <Layout backgroundColor={"_bg_market"} logoFor="ASSISTANT">
            <div className="grid_container_chatbot">
              <div className="container">
                <div className="row">
                  <div className="grid_container_chatbot_wrapper">
                    <div className="grid__wrapper">
                      <div className="col-lg-12 col-md-12 col-xs-12 trial_header_left">
                        <div className="main-slider-left-market">
                          <h1 className="font-page-header ">
                            For IT Helpdesk: Proper process for remote working
                            is the need of the hour in the time of COVID-19
                          </h1>
                        </div>
                      </div>
                      <img src={BlogHeader} />
                      <div className=" mt-5 market_wrapper_page">
                        <p class="font-section-normal-text line-height-2">
                          A pandemic is identified as a rapidly spreading
                          infectious disease that poses a global threat. The
                          result? Decelerated economic growth and social chaos.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          The COVID-19 outbreak is taking the world by storm. On
                          March 11th, 2020, the World Health Organization
                          declared it as a pandemic due to more than 20,000
                          confirmed cases back then. The count has only surged
                          now!
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          According to industry analysts, the ongoing pandemic
                          is undoing business operations by disrupting the
                          supply chain and causing high absenteeism.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          Like a domino effect, this impedes the ability of a
                          business to deliver products and services to the
                          customers. This is particularly true for IT support
                          industries, where support agents regularly need to
                          communicate with customers.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          The high absenteeism of support agents due to panic
                          and fear and asynchronous activity between the rest of
                          the members widen the demand-supply gap. The number of
                          tickets raised to unsolved ratio gradually witness an
                          upward curve.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          Managing the threats posed by this pandemic is
                          necessary for a business to survive, especially for{" "}
                          <a href="https://workativ.com/conversational-ai-platform/conversational-ai-chatbot">
                            IT help desks,
                          </a>{" "}
                          where customer satisfaction is the key. A concrete
                          business continuity plan is, therefore, the need of
                          the hour. It should have a detailed business risk
                          management strategy and business impact analysis. It
                          shall also clearly describe the business’s response to
                          such events and a{" "}
                          <a href="https://workativ.com/conversational-ai-platform/get-over-the-pandemic-with-digital-assistants">
                            detailed recovery plan and policies for managing
                            staff and communication.
                          </a>
                        </p>
                      </div>

                      <NocodeWrapper />

                      <div>
                        <h2 className="font-section-sub-header-small-bold">
                          4 Measures That You Must Take in This Current Scenario
                        </h2>

                        <p class="font-section-normal-text line-height-2">
                          Undeniably, the COVID-19 has brought the world to a
                          halt.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          Companies belonging to the IT sector are setting an
                          example by establishing business continuity procedures
                          during the pandemic. Here are some of the measures you
                          could bring to your workplace and be prepared for
                          similar events in the future.
                        </p>
                        <ul style={{ width: "100%", float: "left" }}>
                          <li class="font-section-normal-text">
                            <span class="font-section-normal-text-medium">
                              Establish a Pandemic Preparedness Framework
                            </span>{" "}
                            – Since IT support teams need to handle customer
                            requests in a prompt manner, they need to ensure a
                            glitch-free service. The best way to do this is to
                            form a team capable of handling all critical
                            business functions and reporting directly to
                            executive management.{" "}
                          </li>
                          <li
                            class="font-section-normal-text mt-3"
                            style="list-style: none"
                          >
                            {" "}
                            The priority of the team should be to arrange the
                            activities of the helpdesk interms of their
                            importance and organize them into tiers. For
                            example, critical IT infrastructures such as network
                            and VPN may be considered for the first and second
                            position of the list.
                          </li>
                          <li class="font-section-normal-text mt-3">
                            <span class="font-section-normal-text-medium">
                              Organize a Proper Pandemic Communication Program
                            </span>{" "}
                            – It is quite natural for your helpdesk agents to
                            feel out of the loop in times of crisis. Be a
                            leader, and address your employees, making them
                            aware of the current scenario and that the company
                            will support them throughout the crisis. Your
                            ultimate goal should be to instill confidence in the
                            minds of your employees and boost their moral
                            strength.{" "}
                          </li>
                          <li class="font-section-normal-text mt-3">
                            <span class="font-section-normal-text-medium">
                              Ensure a Clean Workplace and Personal Hygiene
                              Protocols
                            </span>{" "}
                            – It’s critical that your organization abides by new
                            workplace protocols. Along with that, ensure proper
                            handling protocols for employees and extend your
                            organization’s existing hygiene activities related
                            to cleaning and sanitization products. This way,
                            your employees would feel confident to come to the
                            office.{" "}
                          </li>
                          <li class="font-section-normal-text mt-3">
                            <span class="font-section-normal-text-medium">
                              Review HR Policies and Practices
                            </span>{" "}
                            – It is necessary for you to review the HR policies.
                            Be prepared for an absentee rate as high as 40% and
                            disrupted workflow. To avoid such a situation,
                            consider remote working options.{" "}
                          </li>
                        </ul>
                      </div>

                      <div className=" mt-5 market_wrapper_page">
                        <h5 className="font-section-sub-header-small-bold">
                          The Burden on ITSM Teams in COVID-19 Outbreak{" "}
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          The foundation of ITSM is the belief that IT should be
                          delivered as a service. Typically, customers submit
                          their requests through a portal, filling out a ticket
                          with relevant information. Going forward, the ticket
                          lands up in the IT helpdesk, where the issue is
                          addressed by IT ServiceDesk agents according to
                          importance.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          ITSM teams are tasked with designing, creating,
                          delivering, supporting and managing the lifecycle of
                          IT services. To put it simply, they are responsible
                          for the end-to-end management of these services.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          As the absenteeism rate is shooting up, ITSM teams are
                          constantly facing the heat of service delivery. In
                          other words, the burden on ITSM teams is calibrating
                          at a significant rate.{" "}
                          <a href="https://blog.workativ.com/reasons-why-ai-will-enhance-not-replace-support-agents/">
                            They may find it difficult to address every query of
                            the user and solve their issues.
                          </a>
                        </p>
                      </div>

                      <div className=" mt-5 market_wrapper_page">
                        <h5 className="font-section-sub-header-small-bold">
                          Why is Remote Working the Need of the Hour?{" "}
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          Remote working is a great idea to overcome the hurdles
                          of service delivery in the current scenario. To
                          understand why it is the need of the hour, it’s
                          essential to have a clear idea of what it is and how
                          the COVID-19 turned out to be a pandemic.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          Remote working refers to a working style that allows
                          professionals to work outside the office. It is based
                          on the concept that work does not need to be done in a
                          specific place in order to be executed successfully.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          With governments deciding to lock down entire regions
                          to control the pandemic, remote working is the only
                          option to keep the business cycle spinning. To top it
                          all, this is indeed a great idea to prevent countries
                          from proceeding to a further adverse situation.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          Working remotely helps maintain social distancing, and
                          it goes without any doubt that social distancing is
                          one of the best strategies to overcome this global
                          threat{" "}
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          Several studies backed by concrete evidence suggests
                          that social distancing effectively curtails the spread
                          of this virus. Without any second thought, IT helpdesk
                          agents, who are required to work in teams, are at
                          maximum risk. The spread cannot be controlled with so
                          many service desk agents working and interacting with
                          each other. However, on the other hand,{" "}
                          <a href="https://workativ.com/conversational-ai-platform/automated-resolution">
                            working remotely{" "}
                          </a>
                          can help break the chain as all will be compelled to
                          work from their home.{" "}
                        </p>
                      </div>

                      <div>
                        <h6 className="font-section-sub-header-small-bold">
                          What are the Steps You Can Take to Ensure Hassle-Free
                          Remote Working?
                        </h6>

                        <p class="font-section-normal-text line-height-2">
                          It is essential for you to take necessary steps to
                          ensure a hassle-free remote working for your service
                          desk agents, especially in the current scenario.
                          Here’s what you can do –
                        </p>

                        <ul style={{ width: "100%", float: "left" }}>
                          <li class="font-section-normal-text">
                            <span class="font-section-normal-text-medium">
                              Assign Clear Roles
                            </span>{" "}
                            – Always clear what the role of a particular service
                            agent is. And, this should be known to other team
                            members as well. Assigning clear roles to each of
                            the helpdesk agents ensure that everyone knows who
                            to rely on during a particular problem.{" "}
                          </li>

                          <li class="font-section-normal-text mt-3">
                            <span class="font-section-normal-text-medium">
                              Establish Several Lines of Communication
                            </span>{" "}
                            – Being in the IT service desk industry, it is
                            necessary to take this step. Only relying on e-mails
                            and video calls just won’t do the job. Use a
                            reliable communication tool to ensure glitch-free
                            communication.{" "}
                          </li>
                          <li class="font-section-normal-text mt-3">
                            <span class="font-section-normal-text-medium">
                              Keep the Team on the Same Page
                            </span>{" "}
                            – Use AI-based assistance tools to ensure that all
                            your IT service desk agents are on the same page.
                            Keep them updated about the ticket issues and
                            maintain proper communication with other departments
                            as well.{" "}
                          </li>
                          <li class="font-section-normal-text mt-3">
                            <span class="font-section-normal-text-medium">
                              Build a Centralized Knowledge Base
                            </span>{" "}
                            – Make sure to build a centralized knowledge base
                            from where your service desk agents can access the
                            necessary information to solve tickets.{" "}
                          </li>
                        </ul>
                      </div>

                      <div>
                        <h6 className="font-section-sub-header-small-bold">
                          How Can Automation Help?
                        </h6>

                        <p class="font-section-normal-text line-height-2">
                          Tasks that can be automated should be automated. By
                          leveraging the power of automation, ensure your
                          service desk agents can focus on issues that require
                          attention and critical thinking.
                        </p>

                        <ul style={{ width: "100%", float: "left" }}>
                          <li class="font-section-normal-text">
                            <span class="font-section-normal-text-medium">
                              <a href="https://workativ.com/use-cases/reset-password-automation">
                                {" "}
                                Password Resets
                              </a>
                            </span>{" "}
                            – A recent study states that about 20 to 30% of
                            service desk tickets are related to password reset.
                            There are several AI-based tools available that
                            automate the identity and verification process
                            required to reset a forgotten password using
                            encrypted personal data.{" "}
                          </li>

                          <li class="font-section-normal-text mt-3">
                            <span class="font-section-normal-text-medium">
                              Ticket Categorisation
                            </span>{" "}
                            – It takes a lot of time for the service desk agents
                            to assign tickets to the concerned person.
                            Automating this task using automation tools will
                            help save both time and labor and will also ensure
                            <a href="https://workativ.com/conversational-ai-platform/ticket-automation-chatbot">
                              prompt resolution of tickets.
                            </a>{" "}
                          </li>
                          <li class="font-section-normal-text mt-3">
                            <span class="font-section-normal-text-medium">
                              Update Management
                            </span>{" "}
                            –The task of sending updates to the customers about
                            the status of their ticket or requests can be a bit
                            problematic while working remotely. IT automation
                            tools can help automate this task and ensure timely
                            updates.{" "}
                          </li>
                        </ul>
                      </div>

                      <div>
                        <h6 className="font-section-sub-header-small-bold">
                          How Can Workativ Assistant Help Your Business in Times
                          of Crisis?
                        </h6>

                        <p class="font-section-normal-text line-height-2">
                          Workativ Assistant seamlessly allows creating{" "}
                          <a href="https://workativ.com/conversational-ai-platform/chatbot-best-practices-for-designing-a-conversational-ai">
                            guided conversations
                          </a>{" "}
                          between AI and end-users, using AI.
                        </p>

                        <ul style={{ width: "100%", float: "left" }}>
                          <li class="font-section-normal-text">
                            <span class="font-section-normal-text-medium">
                              <a href="https://workativ.com/use-cases/reset-password-automation">
                                {" "}
                                Provides Prompt Support for User Requests
                              </a>
                            </span>{" "}
                            – Define the response of the virtual assistant to
                            user requests and provide them with prompt support.
                            Along with that, you can also
                            <a href="https://workativ.com/conversational-ai-platform/chatbot-builder">
                              {" "}
                              build conversations
                            </a>{" "}
                            to provide active support to your employees.{" "}
                          </li>

                          <li class="font-section-normal-text mt-3">
                            <span class="font-section-normal-text-medium">
                              Appropriate Response to User Queries
                            </span>{" "}
                            –Through NLP, the virtual assistant can easily
                            understand the intent behind the question or
                            request, providing an appropriate response to user
                            queries. The assistant would be able to deliver
                            support faster and as expected by users so, service
                            delivery is never hindered.{" "}
                          </li>
                          <li class="font-section-normal-text mt-3">
                            <span class="font-section-normal-text-medium">
                              <a href="https://workativ.com/conversational-ai-platform/automated-resolution">
                                {" "}
                                Automated Resolution
                              </a>{" "}
                              via workflows
                            </span>{" "}
                            –Through prebuild automations for several popular
                            applications, virtual assistant can make an action
                            to resolve repetitive employee issues or requests
                            autonomously without the need to use an agent
                            support. This helps free up and deliver first
                            contact resolutions via virtual assistant.{" "}
                          </li>
                        </ul>
                        <p class="font-section-normal-text line-height-2">
                          <a href="https://workativ.com/">
                            Ensure hassle-free remote working
                          </a>{" "}
                          for your employees so that the spread of COVID-19 can
                          be controlled. Stay home, stay safe!{" "}
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          If you’re interested in knowing how Workativ Assistant
                          can be a value-add to your IT helpdesk team, we’d be
                          happy to show you.{" "}
                          <a href="https://workativ.com/conversational-ai-platform/demo">
                            Book a demo{" "}
                          </a>
                          with us at a time of your convenience.
                        </p>
                      </div>
                      <ExistingBlogCta
                        ContentCta="Auto-resolve 60% of Your Employee Queries With Generative AI Chatbot & Automation."
                        ButtonText="Book a Demo"
                        isColor="black"
                        backgroundImage={cta_1}
                        mobileBackgroundImage={cta_1_mob}
                      />
                      <div className="most_popular_links">
                        <div className="most_popular_header font-section-sub-header-bold">
                          Related Articles
                        </div>
                        <div className="most_popular_ul">
                          <ul
                            className="section__ul"
                            style={{ width: "100%", float: "left" }}
                          >
                            <li>
                              <a href="https://workativ.com/conversational-ai-platform/build-or-buy-virtual-agent">
                                Build or buy Virtual Agent
                              </a>
                            </li>
                            <li>
                              <a href="https://workativ.com/conversational-ai-platform/ms-teams-bot-workplace-support-bot">
                                {" "}
                                MS Teams Bot - Your ultimate workplace support
                              </a>
                            </li>
                            <li>
                              <a href="https://workativ.com/conversational-ai-platform/conversational-ai-chatbot">
                                What Is Conversational AI? A Guide to
                                Conversational AI Chatbots
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div className="button_last">
                        <button>
                          <a href="https://blog.workativ.com/category/conversational-ai">
                            Conversational AI
                          </a>
                        </button>
                      </div>
                    </div>
                    <div className="sticky_right_img">
                    <a href="/conversational-ai-platform/case-studies/goto-implements-chatbot-for-it-helpdesk-automation"> {" "} <img src={topImage} alt="goto testimonial cta" />{" "} </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* {isSmall ? null : <OnScrollPopup />} */}

            {/* <GoToPopup />
            <CookiesPoup /> */}
            <RequestForm isFooterForm={true} />
          </Layout>
        </Container>
      </section>
    </>
  );
}

const NocodeWrapper = () => {
  return (
    <div className="nocode_wrapper mt-0 ">
      <h4>No Code - Free Conversational AI Chatbot </h4>
      <button>
        <a
          href="https://assistant.workativ.com/authentication/u/direct-signup"
          alt="Conversational AI chatbot"
        >
          Get Started
        </a>
      </button>
    </div>
  );
};
